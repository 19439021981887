import React from 'react'

function Header() {
  return (
    <div id='logoDiv'>
        <a href='/' style={{alignSelf: 'center'}}><img alt='ais-logo' id='logo' src='https://aischannel-images.s3.eu-west-1.amazonaws.com/ais-channel-logo2022.svg'></img></a>
        <h3 id='title'> <a href="/admin"> Show us your summer! Click here to upload your best photo!</a></h3>
    </div>
  )
}

export default Header