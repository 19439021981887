import axios from "axios";
const ENDPOINT = "/api/auth";

export default function () {
    const config = {
        headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'application/json'}
    };
    return axios.get(`${ENDPOINT}/get_current_user`, config)
    
}
