import React from 'react';
import login from '../services/login';


function Login() {
    const handleSubmit = (e) => {
        e.preventDefault();
        login();
    };
    return (
        <div id='login-div'>
            <img alt='ais-logo' id='logo' src='https://aischannel-images.s3.eu-west-1.amazonaws.com/ais-channel-logo2022.svg'></img>
            <div>
                Welcome, identify yourself to proceed.
                <br />
                <br />
                <button id='loginButton' onClick={handleSubmit} className="pure-button">LOGIN</button>
            </div>
        </div>
    )
}

export default Login