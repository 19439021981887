import React, { useState } from 'react';

function Test({ dataSlider }) {
    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }
    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (

                    <div id={obj} key={obj} className={slideIndex === index + 1 ? "slide active-anim" : "slide"} >
                        <img alt="" src={obj} />
                    </div>

                )
            })}
            {dataSlider.length <= 1 ?
                <div></div> :
                (<div>
                    <button onClick={nextSlide} className="btn-slide next">
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                    <button onClick={prevSlide} className="btn-slide prev">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>
                </div>)
            }

            <div className="container-dots">
                {Array.from({ length: dataSlider.length }).map((item, index) => (
                    <div key={item} onClick={() => moveDot(index + 1)} className={slideIndex === index + 1 ? "dot active" : "dot"}></div>
                ))}
            </div>
        </div>
    )
}

export default Test