import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { getCommentsById, addNewComment } from '../services/comments';
import getCurrentUser from '../services/getCurrentUser';

function Comment({postId, userEmail}) {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [userName, setUserName] = useState("");
    const config = {
        headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'application/json'}
      };

    useEffect(() => {
        getCommentsById({postId})
        .then((response) => {
            setComments(response.data);
        })
    }, [postId]);

    useEffect(() => {
        getCurrentUser()
        .then((response) => {
            setUserName(response['data'])
        });
    }, []);

    const addComment = () => {
        addNewComment({newComment, postId, userEmail})
        .then((response) =>{
            if (response.data.error){
              console.log('error cause not logged in');
            }else{
              const commentToAdd = { commentText: newComment, postId: postId, useremail: userEmail, datetime: new Date(Date.now()).toLocaleString('default', { month: 'short' }) + ", " + new Date(Date.now()).getDate()
            };
              setComments((list) => [...list, commentToAdd]);
              setNewComment("");
            }
        });
    };
    
    return (

        <div className="rightSide">
            <div className='listOfComments'>
                {comments.map((comment, key) => {
                    return (
                        <div key={comment.commentText} className="message" id={userEmail === comment.username ? "you" : "other"}>
                            <div>
                                
                                <div className="message-content">
                                    <strong className="author">{comment.useremail}</strong> <p className="commentText">{comment.commentText}</p>
                                </div>
                                <div className="message-meta">
                                    <p className='time'>{comment.datetime}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            
            </div>
        
            <div className='addCommentContainer'> 
                <input type="text" placeholder='Comment...' autoComplete='off' value={ newComment } onChange={(event) =>{setNewComment(event.target.value)}} />
                
                <button onClick={addComment}> Submit </button>
            </div>
        </div>
    );
}



export default Comment