import React from 'react'
import "../css/Style.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
const ENDPOINT = '/api'
function FileUpload() {
    const [userEmail, setUserEmail] = useState("");
    const [files, setFile] = useState([]);
    const config = {
        headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'application/json'}
      };

    useEffect(() => {
        axios.get(`${ENDPOINT}/auth/get_current_user`, config)
        .then((data)=>{
            //console.log(data['data'])
            if (data['data'] !== 'false'){
                setUserEmail(data['data']);
            }else{
                window.location.replace(`${ENDPOINT}/auth/login`);
            }
        });
    }, [])

    const uploadHandler = (e) =>{
        e.preventDefault();
        if (userEmail !== ""){
            const msg = {
                file: files[0],
                userEmail:userEmail
            }
            axios.post(`${ENDPOINT}/datas/add`, msg, { headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'multipart/form-data'}})
            .then((response)=>{
                if (!response.data.error){
                    console.log()
                }
            })
            .catch((err)=>{
                console.log(err)
            });
            let flMsg = document.getElementById('messageUploadedFlash');
            flMsg.style.display = 'block';
            setTimeout(()=>flMsg.style.display='none', 5000)
        }
    }
  return (
    <>
        <div className="file-card">
            <br></br>
            <div className="file-inputs">
            
                <input name='user_file' type="file" onChange={(event)=>setFile(event.target.files)}/>
                <br></br><br></br>
                <button type='submit' onClick={uploadHandler} id='uploadImage'>
                    Upload
                </button>
                <p id='messageUploadedFlash'>File uploaded!</p>
            </div>
            <p className="main">Supported files</p>
            <p className="info">JPG, PNG</p>
        </div>
    </>
  )
}

export default FileUpload