import axios from "axios"
const ENDPOINT = "/api"

const CONFIG = {
  headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'application/json'}
};

export const getCommentsById = ({postId}) => {
  return (
    axios.get(`${ENDPOINT}/comments/byId/${postId}`, CONFIG)
  )
}

export const addNewComment = ({newComment, postId, userEmail}) => {
  return (
    axios.post(`${ENDPOINT}/comments/addComment`, { 
      commentText: newComment, postId: postId, userEmail: userEmail
    }, CONFIG)
  )
}
