import axios from "axios";
const ENDPOINT = '/api';

export default function login() {
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            'X-Requested-With': 'XMLHttpRequest',
            'Content-type': 'application/json'
        }
    };
  return (
    axios.get(`${ENDPOINT}/auth/login`, config)
    .then((response) => {
        axios.get(response['data'], config);        
        window.location.replace(response['data']);
    })
  )
}
