import React from 'react'
import FileUpload from '../hooks/FileUpload';
import Header from './Header';

function Admin() {
  return (
    <div>
      <Header />
        <label>Upload an image below and it will return the AIS Bucket URL to use in Modyo/other AIS services. </label><br></br> <br></br>
        <FileUpload />
    </div>
  )
}

export default Admin