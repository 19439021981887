import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Login from "./components/Login"
import Admin from './components/Admin';
import Home from './components/Home';
//import Comment from './components/Comment';


function App() {

  return (
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
