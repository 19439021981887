import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Comment from './Comment';
import Test from './Test';
import "../css/Style.css";
import getLikedImages from '../services/getLikedImages';
import getCurrentUser from '../services/getCurrentUser';
import { getData } from '../services/getData';
import Header from './Header';
//import logout from '../services/logout';
const ENDPOINT = "/api"
function Home() {
  const [listOfUSers, setLisfOfUsers] = useState([]);
  const [user_email, setUserEmail] = useState(null);
  const [likedImage, setLikedImage] = useState(0);
  const [showComments, setShowComments] = useState(false);
  
  let navigate = useNavigate();
  const config = {
    headers:{"Access-Control-Allow-Origin": "*", 'Content-type': 'application/json'}
  };

  /*const handleLogout = () => {
    logout()
    .then(() => navigate("/login"))
  }*/

  useEffect(() => {
    getCurrentUser()
    .then((data) => {
      setUserEmail(data['data'])
      if (data['data'] !== "false"){
        console.log('stay home')
        getLikedImages(data['data'])
        .then((data) => {
          setLikedImage(data['data']['id'])
        })
      }else{
        console.log('go to login')
        navigate("/login");
      }
    })
    .catch(err => console.log('err', err))

    getData()
    .then((data) => {
      setLisfOfUsers(data['data'])
    })

  }, []);


  const likePhoto = (photo_id) => {
    const values = {
      photoId: photo_id,
      userEmail: user_email
    }
    axios.post(`${ENDPOINT}/datas/like_image`, values, config)
    .then(data => {
      setLikedImage(data['data']['id']);
      if (data['data']['id'] !== 0){
        document.getElementById('liked')
      }
    });
  }
  

  
  return (
    <div>
      <Header />
      <div className="base-div">
        {
          listOfUSers?.map((value, key) => {
            if(value.data.length >= 1){
              return (
                <div key={ key } value={ value.id } className="userContent">
                    <div className="body-img">
                      <Test dataSlider={value.data} />                
                      <div id='icons-div' style={{textAlign: "left"}}>
                        <i className="heart" style={{fontSize:"26px"}} id={likedImage >=0 && likedImage === value.id ? "liked" : "not-liked"} onClick={() => {
                          likePhoto(value.id);
                          }}></i>
                        <i className="fa fa-comment-o" aria-hidden="true" style={{fontSize: "29px"}} onClick={() => {
                          showComments === false ? setShowComments(value.id) : setShowComments(false);
                        }}></i>
                      </div>
                    </div>
                  
                    {showComments !== false && showComments===value.id && user_email !== ""? (
                      <div key={ key } value={ value.id } className="userContent">
                      <Comment postId={ value.id } userEmail={ user_email }/></div>
                      
                    ) : (
                      <div className='comments-div' style={{display:"none"}}></div>
                      
                    )}
                </div>
              )
            }
          })
        }

      </div>
    </div>
  )
}

export default Home